

























































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Categoria extends Vue {
  private nombreWeb: any = "";
  private culturales: Array<any> = [];
  private currentPage = 1;
  private total = 0;
  private paginaCargada = false;
  private categorias: Array<any> = [];
  private id_categoria = "";
  private id_tipo: any = null;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 12)) {
      this.getInfoCulturalesCategoria();
    }
  }

  @Watch("id_categoria")
  private selectCat(newValue: any) {
    this.getInfoCulturalesCategoria();
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      if (this.$router.currentRoute.params.nombre == "artistas") {
        this.id_tipo = 1;
      } else {
        this.id_tipo = 2;
      }
      this.getInfoCulturalesCategoria();
      this.linkCanonical(this.nombreWeb);
    }
  }

  private linkCanonical(nombreWeb) {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical"; 
    canonicalLink.href = 'https://muniarica.cl/cultura/categoria/' + nombreWeb; 
    document.head.appendChild(canonicalLink);
  }

  private enviarEmail(email) {
    let mailtoLink = "mailto:" + email;
    window.location.href = mailtoLink;
  }

  private filtrarCategoria(id) {
    if (id == this.id_categoria) {
      this.borrarFiltro();
    } else if (id != this.id_categoria) {
      this.currentPage = 1;
      this.id_categoria = id;
    } else {
      this.id_categoria = id;
    }
  }

  private getInfoCulturalesCategoria() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    var path = "?id_tipo=" + this.id_tipo;

    if (this.id_categoria && this.id_categoria.length > 0) {
      // console.log(this.id_categoria);
      if (path == "") {
        path = path + "?";
      } else {
        path = path + "&";
      }

      path = path + "id_categoria=" + this.id_categoria;
    }

    if (path == "") {
      path = path + "?pagina=" + this.currentPage;
    } else {
      path = path + "&pagina=" + this.currentPage;
    }

    this.$axios
      .get("cultura/categoria" + path)
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.culturales = res.data.culturales;
        this.categorias = res.data.categorias;
        for (let i = 0; i < this.culturales.length; i++) {
          if (this.culturales[i].foto) {
            this.culturales[i].foto = base + this.culturales[i].foto;
          } else {
            this.culturales[i].foto = require("@/assets/logos/logo300.webp");
          }
        }
        for (let i = 0; i < this.culturales.length; i++) {
          if (this.culturales[i].banner) {
            this.culturales[i].banner = base + this.culturales[i].banner;
          } else {
            this.culturales[i].banner = require("@/assets/logos/logo300.webp");
          }
        }
        this.total = res.data.total;
        this.$q.loading.hide();
        this.paginaCargada = true;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private borrarFiltro() {
    this.id_categoria = "";
    this.currentPage = 1;
  }
  

  private toLink(item: any, target: any) {
    if (item && item.trim() !== "") {
      if (!item.startsWith("http://") && !item.startsWith("https://")) {
        item = "https://" + item;
      }
      window.open(item, target);
    }
  }

  private llamar(numero) {
    window.open("tel:" + numero);
  }

  private mensaje(numero) {
    window.open("https://wa.me/" + numero);
  }

  private irArtistaRecinto(nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "PublicacionCul" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "PublicacionCul",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}
